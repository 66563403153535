export const busStop = [
    {
        id: '01',
        name: 'Bus Terminus (Plenty Road)',
        eta: 5,
        distance: 100,
        longitude: 145.044908,
        latitude: -37.717574
    },
    {
        id: '02',
        name: 'Jenny Graves Building (Sci. Drive)',
        eta: 10,
        distance: 200,
        longitude: 145.046423,
        latitude: -37.720791
    },
    {
        id: '03',
        name: 'Chisholm College (College Drive)',
        eta: 15,
        distance: 300,
        longitude: 145.050869,
        latitude: -37.723968
    },
    {
        id: '04',
        name: 'La Trobe Apartment- LAN & LAS (College Drive)',
        eta: 20,
        distance: 400,
        longitude: 145.053794,
        latitude: -37.721361
    },
    {
        id: '05',
        name: 'Polaris Town Centre (Copernicus Crescent)',
        eta: 25,
        distance: 500,
        longitude: 145.048047,
        latitude: -37.713629
    }
];
